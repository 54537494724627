import { Store, StoreProvider } from "@features/stores/domain";

const dukanAndEasyOrdersStores = [
  StoreProvider.Dukan,
  StoreProvider.EasyOrders,
];

export function restoreStoresToBeConnected(stores: Store[]) {
  // Reset all stores connectivity except Dukan and EasyOrders
  return stores.map(store => {
    if (!dukanAndEasyOrdersStores.includes(store.provider)) {
      store.canBeConnected = true;
      store.canBeDisconnected = false;
      store.isConnected = false;
    }

    return store;
  });
}

export function disallowStoresConnectionExceptConnectedOnes(stores: Store[]) {
  return stores.map(store => {
    if (
      !dukanAndEasyOrdersStores.includes(store.provider) &&
      !store.isConnected
    ) {
      store.canBeConnected = false;
    }

    return store;
  });
}
