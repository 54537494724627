import cache from "@taager/cache";
import { decrypt, encrypt } from "@taager/encryption";
import events, { EventSubscription } from "@taager/events";
import { get, Random } from "@taager/reinforcements";
import type { AuthUser, User } from "../../domain/entities";
import { USER_LOGIN_CACHE_KEY, USER_SESSION_KEY } from "../constants";
import { UserEventCallback } from "../types/user.types";
import { decodeAccessToken } from "../utils/decode-jwt";

export class UserManager {
  /**
   * User data
   */
  public data?: AuthUser;

  /**
   * Session key
   */
  protected _sessionKey?: string;

  /**
   * Constructor
   */
  public constructor() {
    this.data = cache.get(USER_LOGIN_CACHE_KEY, undefined);
  }

  /**
   * Determine if current user is logged in
   */
  public get isLoggedIn(): boolean {
    return Boolean(this.data);
  }

  /**
   * Get taager id
   */
  public get id(): number {
    return this.get("id") || 0;
  }

  /**
   * Get _id
   */
  public get _id(): string {
    return this.get("_id") || "";
  }

  public get phoneNumber(): string {
    return this.get("phoneNumber") || "";
  }

  public get callingCode(): string {
    return this.get("callingCode") || "20";
  }

  public get email(): string {
    return this.get("email") || "";
  }

  public updateAccessToken(accessToken: string): void {
    this.login({
      ...this.data,
      accessToken,
      verificationState: decodeAccessToken(accessToken),
    } as AuthUser);
  }

  public updateRefreshToken(_refreshToken: string): void {
    // do nothing for now
  }

  /**
   * Get access token value
   */
  public get accessToken(): string {
    return this.get("accessToken") || "";
  }

  /**
   * Empty for now
   */
  public get refreshToken(): string {
    return "";
  }

  public get fullName(): string {
    return this.get("fullName") || "";
  }

  public get selectedMarket(): string {
    return this.get("selectedMarket") || "";
  }

  public get verificationState(): AuthUser["verificationState"] {
    return this.get("verificationState") || {};
  }

  /**
   * Get UI session key
   * This is used to identify the user session when user is not logged in
   * However, it will be sent on each request
   */
  public get uiSessionKey(): string {
    if (this._sessionKey) return this._sessionKey;

    let sessionKey: string | undefined = cache.get(USER_SESSION_KEY);

    if (sessionKey) {
      return sessionKey;
    }

    sessionKey = Random.string(32);
    cache.set(USER_SESSION_KEY, sessionKey);
    this._sessionKey = sessionKey;
    return sessionKey;
  }

  /**
   * Check if user phone number is verified
   */
  public get isPhoneNumberVerified(): boolean {
    const verificationState = this.get("verificationState");
    return !!verificationState?.phoneNumberVerified;
  }

  /**
   * Check if user email is verified
   */
  public get isEmailVerified(): boolean {
    const verificationState = this.get("verificationState");
    return !!verificationState?.emailVerified;
  }

  public get isDataVerified(): boolean {
    const verificationState = this.get("verificationState");
    return !!verificationState?.merchantDataVerified;
  }

  /**
   * Determine if the merchant account is fully verified
   */
  public get isFullyVerified(): boolean {
    return (
      this.isPhoneNumberVerified && this.isEmailVerified && this.isDataVerified
    );
  }

  /**
   * Check if the merchant id (currently national id) is verified
   */
  public get islIdVerified(): boolean {
    const verificationState = this.get("verificationState");
    return !!verificationState?.merchantIdVerified;
  }

  /**
   * Check if the current user account is duplicate
   */
  public get isDuplicateAccount(): boolean {
    return this.get("isDuplicateAccount") || false;
  }

  /**
   * Get value of the given key
   */
  public get<K extends keyof AuthUser>(
    key: K,
    defaultValue?: AuthUser[K],
  ): AuthUser[K] {
    return get(this.data, key, defaultValue);
  }

  /**
   * Set the value of the given key
   */
  public set<K extends keyof AuthUser>(
    key: K,
    value: AuthUser[K],
  ): UserManager {
    return this.update({
      ...(this.data as AuthUser),
      [key]: value,
    });
  }

  /**
   * Log the user in
   */
  public login(data: AuthUser): UserManager {
    this.setData(data);
    events.trigger("user.login", this);
    return this;
  }

  /**
   * Update the user data but keep the access token as is
   */
  public update(newData: User): UserManager {
    this.setData({
      ...newData,
      accessToken: this.accessToken,
    });
    events.trigger("user.update", this);
    return this;
  }

  /**
   * Log the user out, clear its data from the cache
   */
  public logout(): void {
    cache.remove(USER_LOGIN_CACHE_KEY);
    this.data = undefined;
    events.trigger("user.logout", this);
  }

  /**
   * Listen to user login
   */
  public onLogin(callback: UserEventCallback): EventSubscription {
    return events.subscribe("user.login", callback);
  }

  /**
   * Listen to user logout
   */
  public onLogout(callback: UserEventCallback): EventSubscription {
    return events.subscribe("user.logout", callback);
  }

  /**
   * Listen to user update
   */
  public onUpdate(callback: UserEventCallback): EventSubscription {
    return events.subscribe("user.update", callback);
  }

  /**
   * Set user data
   */
  protected setData(data: AuthUser): void {
    this.data = data;
    cache.set(USER_LOGIN_CACHE_KEY, data);
  }

  /**
   * Get features list
   */
  public get features(): string[] {
    return this.get("features") || [];
  }

  /**
   * Check if the user has a feature
   */
  public hasFeature(feature: string): boolean {
    return this.features.includes(feature) || false;
  }

  /**
   * Whether user is signed up from v3
   */
  public get signUpV3(): boolean {
    return this.get("signUpV3") || false;
  }

  /**
   * Convert the user data into an encrypted string
   */
  public toEncryptedString(): string {
    return encrypt(this.data, "user-encrypted-data");
  }

  /**
   * Login from an encrypted string
   */
  public loginFromEncryptedString(encryptedString: string): void {
    const data = decrypt(
      encryptedString,
      "user-encrypted-data",
    ) as unknown as AuthUser;

    this.login(data);
  }
}

export const user = new UserManager();
