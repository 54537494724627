import { country } from "@features/country/data";
import { marketsList } from "@features/country/data/constants";
import { countryAtom } from "@features/country/presentation";
import cache from "@taager/cache";
import { updateCountryToUrlCountryCode } from "app/country/utils";

export function getCountryCodeFromUrl(): string {
  const path = location.pathname;

  const firstPart = path.split("/")[1];

  if (marketsList.includes(firstPart as any)) {
    return firstPart;
  }

  return "";
}

export const COUNTRY_CODE_CACHE_KEY = "ccurl"; // country code url

export function parseBasePath() {
  const path = location.pathname;

  const firstPart = path.split("/")[1];

  if (marketsList.includes(firstPart as any)) {
    if (!cache.get(COUNTRY_CODE_CACHE_KEY)) {
      cache.set(COUNTRY_CODE_CACHE_KEY, firstPart);
    }

    if (countryAtom.get("market") !== firstPart) {
      countryAtom.silentChange("market", firstPart);
      updateCountryToUrlCountryCode();
    }

    return `/${firstPart}`;
  }

  const defaultCountryCode =
    country.shortCode ||
    cache.get(COUNTRY_CODE_CACHE_KEY) ||
    country.defaultMarket;

  const search = location.search; // Get query string (if any)
  const hash = location.hash; // Get fragment (if any)
  // now we need to reload same path but with default market path
  const url = `/${defaultCountryCode}${path}${search}${hash}`;

  window.location.href = url;

  return `/${defaultCountryCode}`;
}
