// it is important to not calling the repository from the top level of the feature i.e `@features/user` or `@features/shared`
// why? because it will create a circular dependency, instead, we should call the repository from the nearest directory of the file
// i.e `data/repositories` or `domain/types` to avoid circular dependency
import { CommercialCategoryRepository } from "@features/catalog/data";
import { type CommercialCategoryRepositoryContract } from "@features/catalog/domain";
import { CountriesRepository } from "@features/country/data";
import { type CountriesRepositoryContract } from "@features/country/domain";
import {
  FirebaseRemoteConfigRepository,
  UserFeaturesRepository,
} from "@features/feature-manager/data";
import { type FeaturesListRepositoryContract } from "@features/feature-manager/domain";
import { MerchantInsightsRepository } from "@features/insights/data";
import { MerchantInsightsRepositoryContract } from "@features/insights/domain/types/insights-repository.contract";
import { NotificationsRepository } from "@features/notifications/data";
import { type NotificationsRepositoryContract } from "@features/notifications/domain";
import { OrdersRepository } from "@features/orders/data/repositories/order.repository";
import { OrdersRepositoryContract } from "@features/orders/domain";
import { ProductsRepository } from "@features/products/data";
import { ProductsRepositoryContract } from "@features/products/domain";
import { MarketClosureRepository } from "@features/shared/data";
import { UtilsRepository } from "@features/shared/data/repositories/utils.repository";
import { MarketClosureRepositoryContract } from "@features/shared/domain";
import { UtilsRepositoryContract } from "@features/shared/domain/types/utils-repository.contract";
import { getUserDevice } from "@features/shared/presentation/utils/get-user-device";
import { DukanRepository, StoresRepository } from "@features/stores/data";
import {
  DukanRepositoryContract,
  type StoresRepositoryContract,
} from "@features/stores/domain";
import {
  AuthRepository,
  MerchantRepository,
  ProfileRepository,
  UserDevice,
} from "@features/user/data";
import {
  UserProfileRepositoryContract,
  type AuthRepositoryContract,
  type MerchantRepositoryContract,
} from "@features/user/domain";
import { WalletRepository } from "@features/wallet/data/repositories/wallet.repository";
import { WalletRepositoryContract } from "@features/wallet/domain";
import { KYCRepository } from "../features/kyc/data";
import { type KYCRepositoryContract } from "../features/kyc/domain";
import { PreOrderRepository } from "../features/pre-order/data";
import { type PreOrderRepositoryContract } from "../features/pre-order/domain";
import { singleton } from "./utils";

// Application resolvers should be listed here like
export function resolveCountriesRepository(): CountriesRepositoryContract {
  return singleton("countries", () => new CountriesRepository());
}

/**
 * Resolve auth repository
 */
export function resolveAuthRepository(): AuthRepositoryContract {
  return singleton("auth", () => new AuthRepository());
}

/**
 * Resolve user features repository
 */
export function resolveUserFeaturesRepository(): FeaturesListRepositoryContract {
  return new UserFeaturesRepository();
}

/**
 * Resolve firebase features repository
 */
export function resolveFirebaseRemoteConfigRepository(): FeaturesListRepositoryContract {
  return new FirebaseRemoteConfigRepository();
}

export function resolveKYCRepository(): KYCRepositoryContract {
  return new KYCRepository();
}

export function resolvePreOrderRepository(): PreOrderRepositoryContract {
  return new PreOrderRepository();
}

export function resolveCommercialCategoriesRepository(): CommercialCategoryRepositoryContract {
  return new CommercialCategoryRepository();
}

export function resolveMerchantRepository(): MerchantRepositoryContract {
  return new MerchantRepository();
}

export function resolveNotificationsRepository(): NotificationsRepositoryContract {
  return new NotificationsRepository();
}

export function resolveUserDevice(): UserDevice {
  return getUserDevice();
}

export function resolveProductsRepository(): ProductsRepositoryContract {
  return new ProductsRepository();
}

export function resolveProfileRepository(): UserProfileRepositoryContract {
  return new ProfileRepository();
}

export function resolveStoresRepository(): StoresRepositoryContract {
  return new StoresRepository();
}

export function resolveDukanRepository(): DukanRepositoryContract {
  return new DukanRepository();
}

export function resolveWalletRepository(): WalletRepositoryContract {
  return new WalletRepository();
}

export function resolveMarketClosureRepository(): MarketClosureRepositoryContract {
  return new MarketClosureRepository();
}

export function resolveInsightsRepository(): MerchantInsightsRepositoryContract {
  return new MerchantInsightsRepository();
}

export function resolveUtilsRepository(): UtilsRepositoryContract {
  return new UtilsRepository();
}

export function resolveOrdersRepository(): OrdersRepositoryContract {
  return new OrdersRepository();
}
