export enum CurrencyCode {
  OMR = "OMR",
  SAR = "SAR",
  AED = "AED",
  IQD = "IQD",
  KWD = "KWD",
  EGP = "EGP",
}

/**
 * Represents a currency in the system
 */
export type Currency = {
  code: CurrencyCode;
  name: string;
};
