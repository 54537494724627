import { countriesList } from "@features/country/data";
import {
  WalletTransactionDetailType,
  WalletTransactionHistory,
  WithdrawalsListResponse,
  type Wallet,
} from "@features/wallet/domain";
import { trans } from "@taager/localization";
import { format } from "date-fns";
import {
  WalletModel,
  WalletTransactionsHistoryListModel,
  WithdrawalsListRequestsModel,
} from "./../models/wallet.model";

export function mapWallets(wallets: WalletModel[]): Wallet[] {
  return wallets.map(wallet => {
    return {
      id: wallet.currency,
      currency: {
        code: wallet.currency,
        name: trans(`currencies.${wallet.currency}`),
      },
      country: countriesList.getByCurrencyCode(wallet.currency)!,
      amountsOwedToTaager: wallet.amountsOwedToTaager,
      eligibleAmount: wallet.eligibleAmount,
      expectedAmount: wallet.expectedAmount,
      ordersCountForExpectedAmount: wallet.ordersCountForExpectedAmount,
    };
  });
}

export function mapWithdrawalsList(
  data: WithdrawalsListRequestsModel,
  pageSize: number,
): WithdrawalsListResponse {
  const totalPages = Math.ceil(data.count / pageSize);

  return {
    totalPages,
    requests: data.withdrawals.map(withdrawal => ({
      ...withdrawal,
      phoneNumber: withdrawal.phoneNum,
      createdAt: new Date(withdrawal.createdAt).toLocaleDateString(),
    })),
  };
}

export function mapWalletTransactions(
  data: WalletTransactionsHistoryListModel,
  pageSize: number,
): WalletTransactionHistory {
  const totalPages = Math.ceil(data.count / pageSize);

  return {
    transactions: data.transactions.map(transaction => ({
      amount: transaction.amount,
      category: transaction.category,
      date: format(new Date(transaction.transactionDate), "dd/MM/yyyy HH:mm"),
      currency: {
        code: transaction.currency,
        name: trans(`currencies.${transaction.currency}`),
      },
      details: {
        ...transaction.details,
        type: transaction.details.type.toLocaleLowerCase() as WalletTransactionDetailType,
      },
      notes: transaction.notes,
    })),
    totalPages,
  };
}
