/* eslint-disable guard-for-in */
import { PreOrderRequestsListModel } from "@features/pre-order/data/models";
import { BaseRepository } from "@features/shared/data";
import {
  PreOrderExternalProductRequestPayload,
  PreOrderExternalProductsListParams,
  PreOrderInternalProductsListParams,
  PreOrderListParams,
  PreOrderProductsListData,
  PreOrderRepositoryContract,
  PreOrderRequestPayload,
  PreOrderRequestsListData,
  PreOrderStatuses,
} from "../../domain";
import { preOrderAPI } from "../constants";
import { mapPreOrderRequestsListData } from "../mappers";

export class PreOrderRepository
  extends BaseRepository
  implements PreOrderRepositoryContract
{
  public async list(
    params?: PreOrderListParams,
  ): Promise<PreOrderRequestsListData> {
    const { data } = await this.http.get<PreOrderRequestsListModel>(
      preOrderAPI.list,
      {
        params,
      },
    );

    return mapPreOrderRequestsListData(data);
  }

  public async listUpcomingRequests(
    params?: PreOrderListParams,
  ): Promise<PreOrderRequestsListData> {
    const { data } = await this.http.get<PreOrderRequestsListModel>(
      preOrderAPI.listUpcomingRequests,
      {
        params: {
          ...params,
          status: PreOrderStatuses.DOWN_PAYMENT_OFFERED,
        },
      },
    );

    return mapPreOrderRequestsListData(data, params?.pageSize);
  }

  public async listRequests(
    params?: PreOrderListParams,
  ): Promise<PreOrderRequestsListData> {
    const { data } = await this.http.get<PreOrderRequestsListModel>(
      preOrderAPI.listRequests,
      {
        params: {
          ...params,
          excludeStatus: PreOrderStatuses.DOWN_PAYMENT_OFFERED,
        },
      },
    );

    return mapPreOrderRequestsListData(data, params?.pageSize);
  }

  public async listProducts(
    _params?: PreOrderInternalProductsListParams,
  ): Promise<PreOrderProductsListData> {
    // TODO: Implement this
    // const { data } = await this.http.get<PreOrderProductsListModel>(
    //   preOrderAPI.listRequests,
    //   { params },
    // );
    // return mapPreOrderProductsListData(data);
    throw new Error("Not implemented");
  }

  public async listExternalProducts(
    _params?: PreOrderExternalProductsListParams,
  ): Promise<PreOrderProductsListData> {
    // const { data } = await this.http.get<PreOrderProductsListModel>(
    //   preOrderAPI.listExternalProducts,
    //   { params },
    // );
    // return mapPreOrderProductsListData(data);
    throw new Error("Not implemented");
  }

  public async createPreOrderRequest(
    data: PreOrderRequestPayload,
  ): Promise<void> {
    await this.http.post(preOrderAPI.createPreOrderRequest, data);
  }

  public async createExternalProductPreOrderRequest(
    data: PreOrderExternalProductRequestPayload,
  ): Promise<void> {
    await this.http.post(
      preOrderAPI.createExternalProductPreOrderRequest,
      data,
    );
  }

  public async rejectPreOrderRequest(id: string): Promise<void> {
    await this.http.put(preOrderAPI.rejectPreOrderRequest(id), {});
  }

  public async acceptPreOrderOffer(
    requestId: string,
    offerId: number,
  ): Promise<void> {
    await this.http.post(preOrderAPI.acceptPreOrderOffer(requestId), {
      offerId,
    });
  }
}
