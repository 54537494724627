import { Nationality } from "@features/country/data";
import { getLocalizedValue } from "@features/drivers/data";
import {
  com,
  getCountryFromIsoCode2,
  getSupportedNationalities,
} from "@taager-experience-shared/country-resolver";
import { getCurrentLocaleCode } from "@taager/localization";

const languages = {
  en: "English",
  ar: "Arabic",
};

export function getNationalitiesList(): Nationality[] {
  const language = languages[getCurrentLocaleCode()];
  const nationalities = getSupportedNationalities(com.taager.locale[language]);

  return nationalities.map(nationality => {
    return {
      code: nationality.isoCode3,
      name: getLocalizedValue(nationality),
    } as Nationality;
  });
}

export function getCountryIsoCode3FromIsoCode2(isoCode2: string): string {
  return getCountryFromIsoCode2(isoCode2.toUpperCase())?.isoCode3 || "";
}
