import {
  BaseRepository,
  parseError,
  sharedApiUrls,
} from "@features/shared/data";
import { UtilsRepositoryContract } from "@features/shared/domain/types/utils-repository.contract";

export class UtilsRepository
  extends BaseRepository
  implements UtilsRepositoryContract
{
  public async uploadImage(file: FormData): Promise<string> {
    try {
      const res = await this.http.post<FormData, { filePath: string }>(
        sharedApiUrls.uploadImage,
        file,
      );

      return res.data.filePath;
    } catch (error) {
      throw parseError(error);
    }
  }
}
