export enum OTPVerificationMode {
  EMAIL = "email",
  SMS = "sms",
}

export type OtpRequestInfo = {
  checkCode: string;
  timeUntilNextRequestInSeconds?: number;
  codeExpiryInMinutes?: number;
  codeLength?: number;
  sendDate?: string;
  requestId?: string;
  expiryDate?: string;
  verificationMode?: OTPVerificationMode;
};
