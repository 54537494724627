import { BaseRepository } from "@features/shared/data";
import {
  mapWallets,
  mapWalletTransactions,
  mapWithdrawalsList,
} from "@features/wallet/data/mappers";
import {
  IntlBankTransferData,
  PayoneerTransferData,
  type Wallet,
  type WalletCredentials,
  type WalletPasswordReset,
  type WalletRepositoryContract,
  type WalletTransactionFilter,
  type WalletTransactionHistory,
  type WithdrawalsList,
  type WithdrawalsListResponse,
} from "@features/wallet/domain";
import {
  OTPVerificationMode,
  type OtpRequestInfo,
} from "@features/wallet/domain/types/otp.types";
import {
  WithdrawalPayload,
  type ChangePasswordResponse,
  type ForgotPasswordPayload,
  type WalletLoginPayload,
  type WalletLoginResponse,
} from "@features/wallet/domain/types/wallet-data.types";
import { getCurrentLocaleCode } from "@taager/localization";
import { GenericObject } from "@taager/reinforcements";
import {
  WalletModel,
  WalletTransactionsHistoryListModel,
  WithdrawalsListRequestsModel,
} from "../models/wallet.model";
import { walletApiUrls } from "./../constants";

export class WalletRepository
  extends BaseRepository
  implements WalletRepositoryContract
{
  /**
   * Authenticates user with wallet credentials
   */
  public async login(params: WalletLoginPayload): Promise<WalletLoginResponse> {
    const { data } = await this.http.post<
      WalletLoginPayload,
      WalletLoginResponse
    >(walletApiUrls.login, params);
    return data;
  }

  /**
   * Initiates forgot wallet password process
   */
  public async forgotPassword(params: ForgotPasswordPayload): Promise<void> {
    await this.http.patch<ForgotPasswordPayload, void>(
      walletApiUrls.forgotPassword,
      params,
    );
  }

  /**
   * Resets wallet password
   */
  public async resetPassword(params: WalletPasswordReset): Promise<void> {
    await this.http.patch<WalletPasswordReset["data"], void>(
      walletApiUrls.resetPassword(params.id, params.resetToken),
      params.data,
    );
  }

  /**
   * Changes wallet password
   */
  public async changePassword(
    params: WalletCredentials,
  ): Promise<ChangePasswordResponse> {
    const { data } = await this.http.post<
      WalletCredentials,
      ChangePasswordResponse
    >(walletApiUrls.changePassword, params);
    return data;
  }

  /**
   * Retrieves wallet transaction history
   */
  public async getWalletTransactionHistory(
    params: WalletTransactionFilter,
  ): Promise<WalletTransactionHistory> {
    const { data } = await this.http.get<{
      data: WalletTransactionsHistoryListModel;
    }>(walletApiUrls.transactionHistory, { params });

    return mapWalletTransactions(data.data, params.pageSize);
  }

  /**
   * Downloads transactions as a excel sheet
   */
  public async getTransactionsSheet(
    params: WalletTransactionFilter,
  ): Promise<Blob> {
    const { data } = await this.http.get<Blob>(
      walletApiUrls.transactionsSheet,
      {
        params,
        responseType: "blob",
        headers: {
          Accept: "text/csv",
        },
      },
    );
    return data;
  }

  /**
   * Retrieves all wallets for the user
   */
  public async list(): Promise<Wallet[]> {
    const { data } = await this.http.get<{
      data: WalletModel[];
    }>(walletApiUrls.wallets);

    return mapWallets(data.data);
  }

  /**
   * Retrieves list of withdrawals
   */
  public async getWithdrawalsList(
    params: WithdrawalsList,
  ): Promise<WithdrawalsListResponse> {
    const { data } = await this.http.post<
      WithdrawalsList,
      { data: WithdrawalsListRequestsModel }
    >(walletApiUrls.withdrawalsList, params);

    return mapWithdrawalsList(data.data, params.pageSize);
  }

  /**
   * Requests OTP for withdrawal
   * @param verificationMode - The mode of OTP verification (SMS or EMAIL)
   */
  public async requestWithdrawalOtp(
    verificationMode: OTPVerificationMode,
  ): Promise<OtpRequestInfo> {
    const endpointsList = {
      [OTPVerificationMode.SMS]: walletApiUrls.withdrawalPhoneOtp,
      [OTPVerificationMode.EMAIL]: walletApiUrls.withdrawalEmailOtp,
    };

    const endpoint = endpointsList[verificationMode];

    const payload =
      verificationMode === OTPVerificationMode.EMAIL
        ? { context: "withdrawal", selectedLanguage: getCurrentLocaleCode() }
        : {};

    const { data } = await this.http.post<GenericObject, OtpRequestInfo>(
      endpoint,
      payload,
    );

    return data;
  }

  /**
   * Creates a secure withdrawal request with OTP verification
   */
  public async createMobileWithdrawalRequest(
    params: WithdrawalPayload,
  ): Promise<void> {
    await this.http.post<WithdrawalPayload, void>(
      walletApiUrls.withdrawalRequest,
      params,
    );
  }

  /**
   * Creates a secure international bank withdrawal with OTP
   */
  public async createBankWithdrawal(
    paymentDetails: IntlBankTransferData,
  ): Promise<void> {
    const bankType = paymentDetails.bankType;

    const endpoint =
      bankType === "international"
        ? walletApiUrls.intlBankTransfer
        : walletApiUrls.egpBankTransfer;

    delete paymentDetails.bankType;

    await this.http.post<IntlBankTransferData, void>(endpoint, paymentDetails);
  }

  /**
   * Creates a secure Payoneer withdrawal with OTP
   */
  public async createPayoneerWithdrawal(
    paymentDetails: PayoneerTransferData,
  ): Promise<void> {
    await this.http.post<PayoneerTransferData, void>(
      walletApiUrls.payoneerTransfer,
      paymentDetails,
    );
  }
}
