import { getLocalizedValue } from "@features/drivers/data";
import { PreOrderRequestModel } from "@features/pre-order/data/models/pre-order-products-list-data-model";
import noProductImagePlaceholder from "assets/images/no-product-image-placeholder.svg";
import { format } from "date-fns";
import { PreOrderRequestOffer, PreOrderRequestsListData } from "../../domain";
import { PreOrderRequestsListModel } from "../models";

// Requested product quantity for preorder should be
// Wallet amount is insufficient
// super(`Product ${productId} is not available`);
const WAIT_LIST_REASON = {
  en: "You are on a wait list for this SKU. You may consider raising a preorder request on another SKU.",
  ar: "أنت في قائمة انتظار لهذا المنتج. يمكنك تقديم طلب مسبق على منتج آخر.",
};
const PRDOUCT_NOT_AVAILABLE_REASON = {
  en: "Unfortunately the SKU is not available immediately. We will notify you once the SKU is available.",
  ar: "هذا المنتج غير متوفر حاليا. سنقوم بإخطارك بمجرد توفره.",
};
const FULL_QUANTITY_NOT_AVAILABLE_REASON = {
  en: "Please start a new pre-order request for X quantity of the product. The previously locked wallet balance will be unlocked.",
  ar: "يرجى تقديم طلب جديد لكمية X من المنتج. وسيتم إعادة المبلغ المحجوز مسبقا إلي رصيد محفظتك.",
};

const PRICE_CHANGE_REASON = {
  en: "The price of the product has changed. We will notify you once we update the price. You may choose to raise a new pre-order request. The previously locked wallet balance will be unlocked.",
  ar: "لقد تغير سعر المنتج. سنخطرك بمجرد تحديث السعر. يمكنك تقديم طلب جديد وسيتم إعادة المبلغ المحجوز مسبقا إلي رصيد محفظتك.",
};
const PRDOUCT_NO_LONGER_AVAILABLE_REASON = {
  en: "Your request cannot be processed as the product is no more available.",
  ar: "المنتج لم يعد متوفرًا.",
};

export const translateRequestRejectionReason = (reason: string): string => {
  if (!reason) return "";

  switch (reason) {
    case WAIT_LIST_REASON.en:
      return getLocalizedValue(WAIT_LIST_REASON);
    case PRDOUCT_NOT_AVAILABLE_REASON.en:
      return getLocalizedValue(PRDOUCT_NOT_AVAILABLE_REASON);
    case PRICE_CHANGE_REASON.en:
      return getLocalizedValue(PRICE_CHANGE_REASON);
    case PRDOUCT_NO_LONGER_AVAILABLE_REASON.en:
      return getLocalizedValue(PRDOUCT_NO_LONGER_AVAILABLE_REASON);
    default:
      break;
  }
  // check if the reason contains digits (available quantity)
  const reasonContainsDigitsMatch = reason.match(/(\d+)/);
  if (reasonContainsDigitsMatch) {
    // fetching the quantity from the reason string
    const availableQuantity = reasonContainsDigitsMatch[0];
    // replacing the quantity with X to compare it with the english reason
    const matchingReason = reason.split(availableQuantity).join("X");
    if (
      availableQuantity &&
      matchingReason === FULL_QUANTITY_NOT_AVAILABLE_REASON.en
    ) {
      // replacing the X with the quantity from the english reason to the arabic reason
      return getLocalizedValue(FULL_QUANTITY_NOT_AVAILABLE_REASON).replace(
        "X",
        availableQuantity,
      );
    }
  }
  return reason;
};

function calculateReservedAmountFromWallet(
  request: PreOrderRequestModel,
  offer: PreOrderRequestOffer,
) {
  return Math.floor(
    offer.reservedStockQuantity *
      request.productLockingPrice *
      (offer.downPaymentPercentageOfferedByAdmin / 100),
  );
}

export const mapPreOrderRequestsListData = (
  data: PreOrderRequestsListModel,
  pageSize?: number,
): PreOrderRequestsListData => {
  const totalPages = Math.ceil(data.data.count / (pageSize ?? 10));

  return {
    count: data.data.count,
    totalPages,
    requests: data.data.preOrderRequests.map(request => ({
      id: request.requestId,
      requestId: request.requestId,
      currency: request.currency,
      status: request.status,
      offers: request.offers?.map(offer => ({
        ...offer,
        reservedAmountFromWallet: calculateReservedAmountFromWallet(
          request,
          offer,
        ),
      })),
      productObjectId: request.productObjectId,
      externalProduct: request.externalProduct,
      product: {
        id: request.prodId,
        name: request.productName,
        image: request.productPicture || noProductImagePlaceholder,
        lockingPrice: request.productLockingPrice,
      },
      requestedQuantity: request.requestedQuantity,
      downPayment: {
        offeredByAdmin: request.downPaymentPercentageOfferedByAdmin,
        suggestedByMerchant: request.downPaymentPercentageSuggestedByMerchant,
        finalAgreedPercentage: request.finalDownPaymentPercentageAgreed,
      },
      notes: {
        merchant: request.notesAddedByMerchant,
        admin: request.notesAddedByAdmin,
      },
      progress: {
        remainingDays: request.remainingDays,
        fulfilledQuantity: request.fulfilledQuantity,
        progressPercentage: request.progressPercentage,
        expectedAcquisitionDays: request.expectedProductAcquisitionDays,
        expectedArrivalDate: request.expectedProductArrivalDate,
        startedAt: request.startedAt,
      },
      wallet: {
        lockedAmount: request.lockedWalletAmount,
        penalizedAmount: request.penalizedWalletAmount,
        moneyReleaseDates: request.moneyReleaseDates,
      },
      rejectionReason: translateRequestRejectionReason(request.rejectionReason),
      createdAt: format(new Date(request.createdAt), "dd/MM/yyyy"),
      updatedAt: format(new Date(request.updatedAt), "dd/MM/yyyy"),
    })),
  };
};
