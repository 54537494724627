export const preOrderAPI = {
  list: "/api/pre-orders",
  listUpcomingRequests: "/v3/preorder/requests",
  listRequests: "/v3/preorder/requests",
  listExternalProducts: "/api/pre-orders/external-products",
  createPreOrderRequest: "/api/pre-orders/request",
  createExternalProductPreOrderRequest: "/api/pre-orders/external-request",
  rejectPreOrderRequest: (requestId: string): string =>
    `/preorder/requests/${encodeURIComponent(requestId)}/reject-down-payment`,
  acceptPreOrderOffer: (requestId: string): string =>
    `/v2/preorder/requests/${encodeURIComponent(requestId)}/accept-down-payment`,
} as const;

export const preOrderTrackEvents = {
  viewPage: "Preorder-page-load",
  clickScrollToPreOrderProducts: "new_pre_order_top_banner_button_clicked",
  clickScrollToPreOrderExternalProducts:
    "click_scroll_to_pre_order_external_products",
  clickPreOrderProductsTab: "click_pre_order_products_tab",
  clickPreOrderExternalProductsTab: "click_pre_order_external_products_tab",
  clickPreOrderExternalProductOpenForm:
    "click_pre_order_external_product_open_form",
  submitPreOrderExternalProductForm: "submit_pre_order_external_product_form",
};
