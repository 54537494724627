import { type MarketAvailability } from "@features/shared/domain";
import { type MarketAvailabilityModel } from "./../models/market-availability.model";

export function mapMarketAvailability(
  data: MarketAvailabilityModel[],
): MarketAvailability[] {
  const today = new Date();

  return data.map(item => {
    const notifyDate = new Date(item.notifyDate);
    const closingFromDate = new Date(item.closingFromDate);
    const closingToDate = new Date(item.closingToDate);

    const isWithinClosingDates = isDateBetween(
      today,
      closingFromDate,
      closingToDate,
    );

    const isWithinNotifyDates = isDateBetween(
      today,
      notifyDate,
      closingFromDate,
    );

    return {
      type: item.closedFeature,
      isClosed: isWithinClosingDates,
      closeMessage: item.closingMessage,
      ...(isWithinNotifyDates || isWithinClosingDates
        ? { messageState: isWithinNotifyDates ? "notify" : "closed" }
        : {}),
    } as MarketAvailability;
  });
}

function isDateBetween(date: Date, fromDate: Date, toDate: Date): boolean {
  return date >= new Date(fromDate) && date <= new Date(toDate);
}
