import { GenericObject } from "@taager/reinforcements";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export function gTagEvent(eventName: string, payload?: GenericObject): void {
  window.dataLayer.push({
    event: eventName,
    ...payload,
  });
}
