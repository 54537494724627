import { environment } from "environment";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";

const app = initializeApp(environment.firebase);

export function getFirebaseApp() {
  return app;
}

export function initFirebasePerformance() {
  return getPerformance(app);
}
