import { BaseRepository } from "@features/shared/data";
import { mapMarketAvailability } from "@features/shared/data/mappers/map-market-availability";
import { MarketAvailabilityModel } from "@features/shared/data/models/market-availability.model";
import type {
  MarketAvailability,
  MarketClosureRepositoryContract,
} from "@features/shared/domain";
import { sharedApiUrls } from "../constants";

export class MarketClosureRepository
  extends BaseRepository
  implements MarketClosureRepositoryContract
{
  /**
   * Retrieves the current market availability status
   * @returns Promise<MarketAvailability> - Object containing market closure status and message
   */
  public async list(): Promise<MarketAvailability[]> {
    const { data } = await this.http.get<MarketAvailabilityModel[]>(
      sharedApiUrls.marketClosure,
    );

    return mapMarketAvailability(data);
  }
}
