import cache from "@taager/cache";
import { GenericObject } from "@taager/reinforcements";
import user from "user";
import { resolveUserFeaturesRepository } from "../../../../di/app";
import { type FeatureProviderContract } from "../../domain";
import { USER_FEATURES_CACHE_KEY } from "../constants";

class UserFeatureProvider implements FeatureProviderContract {
  /**
   * List of features
   */
  protected featuresList: GenericObject = {};

  protected isLoading = false;

  public async init(): Promise<void> {
    await this.loadFeatures();
  }

  public async isEnabled(featureName: string): Promise<boolean> {
    if (!this.isLoading) {
      return this.featuresList[featureName] || false;
    }

    return this.waitUntilLoaded(() => this.featuresList[featureName] || false);
  }

  public isEnabledSync(featureName: string): boolean | null {
    return this.featuresList[featureName] ?? null;
  }

  public get isLoaded(): boolean {
    return Object.keys(this.featuresList).length > 0 && !this.isLoading;
  }

  public getValue<T>(featureName: string): Promise<T> {
    return this.waitUntilLoaded(() => this.featuresList[featureName] as T);
  }

  /**
   * Load features from API
   */
  protected async loadFeatures(): Promise<void> {
    if (!user.isLoggedIn) return;

    this.isLoading = true;
    const features = await resolveUserFeaturesRepository().list();
    this.setFeaturesList(features);

    this.isLoading = false;
  }

  /**
   * Wait until the features list is loaded
   */
  protected async waitUntilLoaded<T>(callback: () => T): Promise<T> {
    if (this.isLoading) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.waitUntilLoaded(callback));
        }, 50);
      });
    }

    return callback();
  }

  /**
   * Set features list
   */
  public setFeaturesList(featuresList: GenericObject): void {
    this.featuresList = featuresList;

    this.isLoading = false;

    cache.set(USER_FEATURES_CACHE_KEY, featuresList);
  }
}

export const userFeatureProvider = new UserFeatureProvider();
