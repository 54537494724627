import { CurrencyCode } from "@features/shared/domain";
import { WithdrawalPaymentMethod } from "@features/wallet/domain/types";

export enum WithdrawalRequestsStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  IN_PROGRESS = "in_progress",
  PROCESSING = "processing",
  RETURNED = "returned",
}

export type WithdrawalTransaction = {
  id: string;
  taagerId: string;
  fullName: string;
  amount: number;
  currency: CurrencyCode;
  paymentMethod: WithdrawalPaymentMethod;
  phoneNumber: string;
  status: WithdrawalRequestsStatus;
  createdAt: string;
  rejectReason?: string;
};
