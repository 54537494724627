import { country } from "@features/country/data";
import { ORDER_APIS_ENDPOINTS } from "@features/orders/data/constants/order-endpoints";
import {
  CalculateOrderCostRequestModel,
  CalculateOrderCostResponseModel,
  OrderActivityResponseModel,
  OrderActivityWithStatusModel,
  OrderListParamModel,
  OrderModel,
  OrdersFilter,
  OrdersTracking,
} from "@features/orders/data/models";
import { OrdersRepositoryContract } from "@features/orders/domain";
import {
  CanceledOrderEntity,
  FailedOrdersResponseEntity,
  OrderActivityResponseEntity,
  OrderActivityWithStatusEntity,
  OrderActivityWithStatusResponseEntity,
  OrdersListEntity,
  OrderStatusListEntity,
  RateOrderEntity,
} from "@features/orders/domain/entities";
import {
  ChildOrdersFilterEntity,
  ChildOrdersResultEntity,
} from "@features/orders/domain/entities/child-orders.entity";
import { BaseRepository } from "@features/shared/data";
import user from "user";

export class OrdersRepository
  extends BaseRepository
  implements OrdersRepositoryContract
{
  // Get Orders List
  public async list(filters: any): Promise<OrdersListEntity> {
    const defaultFilters = {
      sortBy: "creationDate",
      sortOrder: "descending",
      page: 1,
      pageSize: 10,
    };

    const processedFilters = {
      ...defaultFilters,
      ...filters,
    };

    const filteredParams = Object.keys(processedFilters)
      .filter(
        key =>
          processedFilters[key] !== undefined && processedFilters[key] !== null,
      )
      .reduce((resultObject: any, key) => {
        resultObject[key] = processedFilters[key];
        return resultObject;
      }, {});

    const { data } = await this.http.get<OrdersListEntity>(
      ORDER_APIS_ENDPOINTS.GET_ORDERS_LIST_URL,
      {
        params: filteredParams,
      },
    );

    return data;
  }

  // List Child Orders
  public async getChildOrders(
    childOrdersFilter: ChildOrdersFilterEntity,
  ): Promise<ChildOrdersResultEntity> {
    const params = {
      page: childOrdersFilter?.pageNum,
      pageSize: childOrdersFilter?.pageSize,
      showAllOrders: childOrdersFilter?.showAllOrders,
      taagerId: user?.id,
    };

    // Filter out undefined or null parameters
    const filteredParams = Object.entries(params)
      .filter(([, value]) => value !== undefined && value !== null)
      .reduce<Record<string, any>>((resultObject, [key, value]) => {
        resultObject[key] = value;
        return resultObject;
      }, {});

    const { data } = await this.http.get<ChildOrdersResultEntity>(
      ORDER_APIS_ENDPOINTS.GET_CHILD_ORDERS_URL,
      {
        params: filteredParams,
      },
    );

    return data;
  }

  // List Failed Orders
  public async getFailedOrders(
    page = 1,
    pageSize = 10,
  ): Promise<FailedOrdersResponseEntity> {
    const { data } = await this.http.post<
      OrderListParamModel,
      { data: FailedOrdersResponseEntity }
    >(ORDER_APIS_ENDPOINTS.GET_FAILED_ORDERS_URL, {
      page,
      pageSize,
      country: country.code,
    });

    return data.data;
  }

  // Get Order Activity With Status
  public async getOrderActivityWithStatus(
    filterEntity: OrderActivityWithStatusEntity,
  ): Promise<OrderActivityWithStatusResponseEntity> {
    filterEntity = {
      ...filterEntity,
      pageNum: filterEntity.pageNum || 1,
      pageSize: filterEntity.pageSize || 10,
    };

    const response = await this.http.post<OrderActivityWithStatusEntity, any>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_ACTIVITY_WITH_STATUS_URL,
      filterEntity,
    );

    return response.data;
  }

  // Cancel Order
  public async cancelOrder(cancelOrder: CanceledOrderEntity): Promise<void> {
    return await this.http.patch(
      ORDER_APIS_ENDPOINTS.CANCEL_ORDER_URL,
      cancelOrder,
    );
  }

  // Get Order Status List
  public async getOrderStatusList(): Promise<OrderStatusListEntity> {
    const { data } = await this.http.get<OrderStatusListEntity>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_STATUS_LIST_URL,
    );

    return data;
  }

  // Rate Order
  public async rateOrder(rateOrder: RateOrderEntity): Promise<void> {
    return await this.http.patch<RateOrderEntity, void>(
      ORDER_APIS_ENDPOINTS.RATE_ORDER_URL,
      rateOrder,
    );
  }

  // Get Orders Tracking
  getOrdersTracking(filter: OrdersFilter): Promise<OrdersTracking> {
    throw new Error("Method not implemented.");
  }

  // Calculate Order Prices
  calculateOrderPrices(
    orderData: CalculateOrderCostRequestModel,
  ): Promise<CalculateOrderCostResponseModel> {
    throw new Error("Method not implemented.");
  }

  // Track Order Activity
  public async trackOrderActivity(
    filter?: OrderActivityWithStatusModel,
  ): Promise<OrderActivityResponseModel> {
    const { data } = await this.http.get<OrderActivityResponseEntity>(
      ORDER_APIS_ENDPOINTS.TRACK_ORDER_ACTIVITY_URL(
        filter?.filterObj.orderID || "",
      ),
    );

    return data;
  }

  /**
   * Extract Excel Sheet for User Orders
   */
  public async getUserOrdersExtract(filter: any): Promise<OrderModel[]> {
    const bodyData = { data: filter };

    const { data } = await this.http.post<{ data: OrderModel[] }, any>(
      ORDER_APIS_ENDPOINTS.GET_USER_ORDERS_EXTRACT_URL,
      bodyData,
    );

    return data;
  }

  public async getProductsByProdIds(prodIDs: any): Promise<any> {
    const { data } = await this.http.post<any, any>(
      ORDER_APIS_ENDPOINTS.GET_PRODUCTS_BY_PROD_IDS_URL,
      prodIDs,
    );

    return data.data;
  }
}
