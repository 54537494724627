import { WithdrawalRequestsStatus } from "@features/wallet/domain";

export const walletApiUrl = "/api/wallet";

export const WALLET_LOGIN_CACHE_KEY = "wal_lg"; // Wallet login
export const walletApiUrls = {
  login: "/auth/wallet-login",
  forgotPassword: "/auth/forgotWalletPassword",
  changePassword: "/v1/wallet/change-password",
  resetPassword: (id: string, resetToken: string): string =>
    `/auth/resetWalletPassword/${id}/${resetToken}`,
  transactionHistory: "/wallet/transaction-history",
  transactionsSheet: "/wallet/transaction-history",
  wallets: "/wallet",
  withdrawalsList: "/withdrawals/list",
  withdrawalPhoneOtp: "/withdrawals/request-otp",
  withdrawalEmailOtp: "/users/email/verification/otp",

  //   New APIs Endpoints
  withdrawalRequest: "/withdrawals/request",
  egpBankTransfer: "/withdrawals/request-egyptian-bank-transfer",
  intlBankTransfer: "/withdrawals/request-international-bank-transfer",
  payoneerTransfer: "/withdrawals/request-payoneer",
} as const;

export const WithdrawalRequestsStatuses: readonly WithdrawalRequestsStatus[] =
  Object.values(WithdrawalRequestsStatus);

export const MIN_WITHDRAWAL_AMOUNT_FOR_BANK_TRANSFER_IN_EGYPT = 3000;
