export enum PreOrderStatuses {
  PENDING_APPROVAL = "pending_approval",
  PendingExternalProductApproval = "pending_external_product",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
  DOWN_PAYMENT_OFFERED = "down_payment_offered",
  MONEY_TRANSFERRED_APPROVED = "money_transfer_approved",
  DOWN_PAYMENT_REJECTED_BY_MERCHANT = "down_payment_rejected_by_merchant",
  STARTED = "started",
  DOWN_PAYMENT_AGREED = "down_payment_agreed",
  IN_PROGRESS = "in_progress",
  AWAITING = "awaiting_approval",
  AWAITING_EXTERNAL_PRODUCT_APPROVAL = "awaiting_approval_external",
}
