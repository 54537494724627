import { jwtDecode } from "jwt-decode";

export function decodeAccessToken(accessToken: string): any {
  const data = jwtDecode<any>(accessToken);

  const { user } = data;

  const { verificationState } = user;

  if (verificationState) {
    user.verificationState = verificationState;
  }

  return user;
}
