import { insightUrls } from "@features/insights/data/constants";
import { InsightsType } from "@features/insights/domain/entities/insights.entity";
import { MerchantInsightsRepositoryContract } from "@features/insights/domain/types/insights-repository.contract";
import { BaseRepository } from "@features/shared/data";

export class MerchantInsightsRepository
  extends BaseRepository
  implements MerchantInsightsRepositoryContract
{
  public async getInsightsUrl(
    type: InsightsType,
    id: number,
  ): Promise<{ url: string }> {
    const { data } = await this.http.get<{ url: string }>(
      insightUrls.insightsUrl,
      {
        params: {
          type,
          id,
        },
      },
    );

    return data;
  }
}
